import React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import Layout from "../layout";
import {
  DEAL_STATUS,
  DEAL_STATUS_OPTIONS,
  PROJECT_TAX_CREDIT_TYPE,
} from "../../constants";
import { getProjectDetails, updateProject } from "../../apis/project/base";
import { getProjectValuation } from "../../apis/project/valuation";
import { navigateAndScroll, trimString } from "../../utils/helpers";
import { useAPI, useAppSelector } from "../../utils/hooks";
import {
  resetConfirmModalPropsAction,
  setConfirmModalPropsAction,
  setCurrentProjectAction,
  setCurrentProjectStatusAction,
  setValuationExistAction,
} from "../../utils/redux/slices";
import {
  IAddUpdateProjectResponse,
  IProjectForm,
  IProjectFormErrors,
} from "../../interfaces";

interface IProps {
  children: JSX.Element;
}

export default function ProjectPagesLayout({ children }: IProps): JSX.Element {
  const dispatch = useDispatch();
  const { currentProject } = useAppSelector((s) => s.project);
  const { contentLoaded } = useAppSelector((s) => s.common);

  const styles = useStyles({ contentLoaded });

  const navigate = useNavigate();
  const { projectId } = useParams();

  const {
    callAPI: getProjectDetailsCallAPI,
    loading: loadingProjectDetails,
    errored: getProjectDetailsFiled,
    response: project,
  } = useAPI((projectId) => getProjectDetails(projectId));

  const { callAPI: updateProjectCallAPI } = useAPI<
    IAddUpdateProjectResponse,
    IProjectFormErrors
  >((projectId: number, form: IProjectForm) => updateProject(projectId, form));

  const { callAPI: getProjectValuationCallAPI } = useAPI((projectId) =>
    getProjectValuation(projectId),
  );

  React.useEffect(() => {
    if (currentProject?.id !== Number(projectId)) {
      getProjectDetailsCallAPI(Number(projectId)).then((res) => {
        dispatch(setCurrentProjectAction(res));
      });
      getProjectValuationCallAPI(projectId).then((res) => {
        res && dispatch(setValuationExistAction(Boolean(res[0])));
      });
    }
  }, [projectId]);

  React.useEffect(() => {
    dispatch(
      setCurrentProjectStatusAction({
        loading: loadingProjectDetails,
        errored: getProjectDetailsFiled,
      }),
    );
  }, [loadingProjectDetails, getProjectDetailsFiled]);

  React.useEffect(() => {
    // * Redirecting because the project belongs to a case of base-deal.
    if (
      (project?.deals || []).length > 0 &&
      project?.deals[0]?.parent_deal_id
    ) {
      const deal = project.deals[0];
      navigate(`/deal/${deal.parent_deal_id}/case/${deal.case_id}`);
    }
  }, [project]);

  const onDealChipClick = (id: number) => {
    window.open(`/deal/${id}/case-deal/${id}/general`, "_blank");
  };

  const handleNavigate = (url: string, elementId: string) => {
    navigateAndScroll(() => navigate(url), elementId);
  };

  const updateProjectStatus = async (status: string) => {
    const updatedProject = await updateProjectCallAPI(Number(projectId), {
      status,
    });

    if (currentProject && updatedProject) {
      dispatch(
        setCurrentProjectAction({
          ...currentProject,
          ...updatedProject,
        }),
      );
      dispatch(resetConfirmModalPropsAction());
    }
  };

  const handleConfirmProjectStatusUpdate = async (status: string) => {
    dispatch(
      setConfirmModalPropsAction({
        open: true,
        title: "Confirm Project Status Update",
        description: `Are you sure you want to update the status of this Project and make it 'Active'?`,
        onConfirm: () => updateProjectStatus(status),
      }),
    );
  };

  return (
    <Layout title={currentProject?.name}>
      <>
        {currentProject?.status ===
          DEAL_STATUS_OPTIONS.find((o) => o.value === "ARCH")?.value && (
          <Alert severity="warning">
            {" "}
            This Project has been archived.{" "}
            <span
              className="cursor-pointer font-semibold"
              onClick={() => handleConfirmProjectStatusUpdate("ACT")}
            >
              Click here
            </span>{" "}
            to unarchive.{" "}
          </Alert>
        )}
        {currentProject && (
          <Box className={styles.classes.stickyHeader}>
            <Box className={styles.classes.infoSection}>
              <Link to="/project-list" className={styles.classes.backButton}>
                <ArrowBackIcon />
                <Typography>Back to Projects List</Typography>
              </Link>
              <Box className="flex items-center justify-start">
                <Typography variant="h5" component="h5" marginRight={3}>
                  {trimString(currentProject?.name, 45)}
                </Typography>
                <Chip
                  className="!mb-1"
                  label={DEAL_STATUS[currentProject.status]}
                  color={
                    DEAL_STATUS[currentProject.status] === "Active"
                      ? "success"
                      : "warning"
                  }
                />
              </Box>
              <Box className={styles.classes.chipsContainer}>
                <Box>
                  <Chip label={currentProject?.sponsor_name} />
                  <Chip label={currentProject?.capacity_ac + " MW (AC)"} />
                  <Chip label={currentProject?.capacity_dc + " MW (DC)"} />
                  <Chip label={"BOL: " + currentProject?.bol} />
                  <Chip label={"NTP: " + currentProject?.ntp} />
                  <Chip label={"COD: " + currentProject?.cod} />
                  <Chip
                    label={
                      "Tax Credit Type: " +
                      PROJECT_TAX_CREDIT_TYPE[currentProject?.tax_credit_type]
                    }
                  />
                  {(currentProject.deals || []).length > 4 ? (
                    <Chip
                      label="Deals"
                      variant="outlined"
                      onClick={() =>
                        handleNavigate(
                          `/project/${currentProject?.id}/general`,
                          "project-deals",
                        )
                      }
                    />
                  ) : (
                    (currentProject?.deals || []).map((d, idx) => (
                      <Chip
                        key={idx}
                        label={d.name}
                        variant="outlined"
                        icon={<OpenInNewIcon fontSize="small" />}
                        onClick={() => onDealChipClick(d.id)}
                      />
                    ))
                  )}
                </Box>
              </Box>
              <Divider className={styles.classes.divider} />
            </Box>
          </Box>
        )}
        <Box className={styles.classes.children}>{children}</Box>
      </>
    </Layout>
  );
}
