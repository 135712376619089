import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { format } from "date-fns";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Chip from "@mui/material/Chip";

import useStyles from "./styles";
import { useAppSelector } from "../../utils/hooks";
import { cn, sortArrayOfObjects, trimString } from "../../utils/helpers";
import {
  DEAL_STATUS,
  DEAL_STATUS_OPTIONS,
  PROJECT_STAGES,
  PROJECT_TAX_CREDIT_TYPE,
  PROJECT_TYPES,
  US_STATES,
} from "../../constants";
import {
  ITableSort,
  ITableColumn,
  SetStateAction,
  IProjectTableRow,
} from "../../interfaces";
import Tabs from "../tabs";
import TabbedLayout from "../tabbed-layout";

interface IProps {
  columns: ITableColumn[];
  rows: IProjectTableRow[];
  sortTable: ITableSort;
  setSortTable: SetStateAction<ITableSort>;
  openProjectInNewTab?: boolean;
  heightOptions?: {
    isFilterOpen?: boolean;
    fixedHeight?: boolean;
  };
  isFiltersApplied?: boolean;
}

export default function ProjectsTable({
  columns,
  rows,
  sortTable,
  setSortTable,
  openProjectInNewTab,
  heightOptions,
  isFiltersApplied,
}: IProps): JSX.Element {
  const styles = useStyles({ heightOptions });
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { ctrlPressed } = useAppSelector((s) => s.common);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const goToProject = (projectId: number) => {
    if (openProjectInNewTab || ctrlPressed) {
      window.open(`/project/${projectId}/general`, "_blank");
      return;
    }
    navigate(`/project/${projectId}/general`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(rows, sortTable?.orderBy, sortTable?.order).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, sortTable, page, rowsPerPage, searchParams],
  );

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer classes={{ root: styles.classes.tableContainer }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={styles.classes.header}>
            <TableRow>
              {columns.map((column, idx) => {
                return (
                  <TableCell
                    key={idx}
                    align={column.align as "left"}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      disabled={column.id === "action"}
                      active={sortTable.orderBy === column.id}
                      direction={
                        sortTable.orderBy === column.id
                          ? sortTable.order
                          : "asc"
                      }
                      onClick={() => sortRows(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={columns.length}>
                  {isFiltersApplied
                    ? "No Projects match the filters applied, please clear the filters or add a new Project"
                    : "There are no Projects in place currently, please add one."}
                </TableCell>
              </TableRow>
            )}

            {rows.length !== 0 && visibleRows.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={columns.length}>
                  No Projects match the selected tab
                </TableCell>
              </TableRow>
            )}

            {visibleRows.map((project, idx) => {
              return (
                <TableRow
                  hover
                  key={idx}
                  tabIndex={-1}
                  className={cn("cursor-pointer")}
                  onClick={() => goToProject(project.id)}
                  data-pw={`project-table-row-${idx + 1}`}
                >
                  <TableCell align="left" title={project.name}>
                    {trimString(project.name, 40)}
                  </TableCell>
                  <TableCell align="left">{project.sponsor_name}</TableCell>
                  <TableCell align="left">
                    {PROJECT_TYPES[project.type]}
                  </TableCell>
                  <TableCell align="left">{US_STATES[project.state]}</TableCell>
                  <TableCell align="left">{project.capacity_ac} MW</TableCell>
                  <TableCell align="left">
                    {project.stage}: {PROJECT_STAGES[project.sub_stage]}
                  </TableCell>
                  <TableCell align="left">{project.cod}</TableCell>
                  <TableCell align="left">
                    {
                      PROJECT_TAX_CREDIT_TYPE[
                        project.tax_credit_type as keyof typeof PROJECT_TAX_CREDIT_TYPE
                      ]
                    }
                  </TableCell>
                  <TableCell align="left">
                    <Chip
                      label={DEAL_STATUS[project.status]}
                      color={project.status === "ACT" ? "success" : "warning"}
                    />
                  </TableCell>
                  <TableCell align="left">
                    {format(new Date(project.modified), "M/d/yyyy")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        className={cn("bg-table-gray")}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
